import React from 'react';
import Layout from '../components/layout';

export default function AboutUs(props) {
	return (
		<Layout location={props.location}>
			<h2> About US </h2>
			<p>
				LOREM IPSUM The classic latin passage that just never gets old, enjoy as much (or as
				little) lorem ipsum as you can handle with our easy to use filler text generator.
				Lorem ipsum dolor sit amet, consectetur adipiscing elitriatur. HODOR I
			</p>
			<br />
			<p>
				LOREM IPSUM The classic latin passage that just never gets old, enjoy as much (or as
				little) lorem ipsum as you can handle with our easy to use filler text generator.
				Lorem LOREM IPSUM The classic latin passage that just never gets old, enjoy as much
				(or as little) lorem ipsum as you can handle with our easy to use filler text
				generator. Lorem ipsum dolor sit amet, consectetur adipiscing elitriatur. HODOR I
			</p>
			<br />
			<p>
				LOREM IPSUM The classic latin passage that just never gets old, enjoy as much (or as
				l I
			</p>
		</Layout>
	);
}
